import React, { Fragment, useContext,Component, useEffect, useState } from 'react';
import SliderMainParticle from '../components/SliderMainParticle';
import FeatureBox from '../components/FeatureBox';
import CarouselCollection from '../components/CarouselCollection';
import CarouselNew from '../components/CarouselNew';
import styled from "styled-components";
import Footer from '../components/footer';
import Slider from "react-slick";
import { createGlobalStyle } from 'styled-components';
import { ethers } from 'ethers';
import { GlobalContext } from "components/app";
import { NavLink } from 'react-bootstrap';
import { Link } from "@reach/router";
import { ShowMinterButton } from 'components/menu/header';
import { Redirect } from '@reach/router';
import CharacterNFTABI from '../../ContractABI/CharacterNFTABI'
import MarketplaceABI from '../../ContractABI/MarketplaceABI'
import { marketplaceAddress,characterAddress } from 'ContractABI/constants';
const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar .search #quick_search{
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-3{
    display: block !important;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-3{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
`;

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 260px;
  overflow: hidden;
  border-radius: 8px;
`;
class CustomSlide extends Component {
    render() {
      const { index, ...props } = this.props;
      return (
        <div {...props}></div>
      );
    }
  }

const AllNFTs = () => {
    var settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        adaptiveHeight: 300,
        responsive: [
          {
            breakpoint: 1900,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true
            }
          },
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true
            }
          }
        ]
      }
  const { isLogin,walletAddress, setIsLogin, listed, setListed ,setSelectedNFT} = useContext(GlobalContext);

  const BuyNow = async (contractAddress,tokenID,price)=>{
       var provider = new ethers.providers.Web3Provider(window.ethereum);
       var signer = provider.getSigner();
       var MarketPlaceContract = new ethers.Contract(
        marketplaceAddress,
        MarketplaceABI,
         signer
       );
       var CharacterContract = new ethers.Contract(
         characterAddress,
         CharacterNFTABI,
         signer
       );
       let receipt = await MarketPlaceContract.BuyNFT(contractAddress, tokenID,{ value: ethers.utils.parseEther(price) }) 
       console.log(receipt);
  }
  //const [isLoading, setLoading] = useState(true);
//   useEffect(() => {

//     async function fetchData() {
//       var provider = new ethers.providers.Web3Provider(window.ethereum);
//       var signer = provider.getSigner();
//       var MarketPlaceContract = new ethers.Contract(
//         "0x921469CBdddf22ACe4995f4f5faa456A16155336",
//         MarketplaceABI,
//         signer
//       );
//       var CharacterContract = new ethers.Contract(
//         "0xB69Da384c39f11A561985097dA7D26E131Be67fb",
//         CharacterNFTABI,
//         signer
//       );

//       let listData = [];
//       let data = await MarketPlaceContract.getHistory()
//       for (let i = 0; i < data.length; i++) {
//         //console.log(ethers.utils.formatEther(data[i].price));
//         let jsonData = await CharacterContract.tokenURI(Number(data[i].tokenID))
//         const res = await fetch(jsonData);
//         const d = await res.json();

//         let singleEntry = {
//           contractAddress: data[i].contractAddress,
//           isAvailable: data[i].isAvailable,
//           owner: data[i].owner,
//           tokenID: Number(data[i].tokenID),
//           price: ethers.utils.formatEther(data[i].price),
//           jsonData: d
//         }
//         listData.push(singleEntry);
//       }

//       setListed(listData);
//       setLoading(false);
//     }
//     fetchData();

//   }, [])
if(!isLogin){
  
    return <Redirect to="/"/>
}
  return (
    <div>
      <GlobalStyles />
      {
        isLogin  &&
        <Fragment>
          {/* <ShowMinterButton/> */}
          <br />
          <br /><br /><br />
          <section className='container no-top'>
            <div className='row'>
              <div className='col-lg-12 text-center'>
                <h2 className='style-2'>Browse Category</h2>

                <div className="small-border"></div>
              </div>
            </div>
            <div className='container px-0'>
              <div className="row">
                <div className="col-lg-2 col-sm-4 col-6 mb30">
                  <span className="box-url">
                    <i className="fa fa-image"></i>
                    <h4>Characters</h4>
                  </span>
                </div>

                <div className="col-lg-2 col-sm-4 col-6 mb30">
                  <span className="box-url">
                    <i className="fa fa-image"></i>
                    <h4>Game Assets</h4>
                  </span>
                </div>

                <div className="col-lg-2 col-sm-4 col-6 mb30">
                  <span className="box-url">
                    <i className="fa fa-image"></i>
                    <h4>Music</h4>
                  </span>
                </div>

                <div className="col-lg-2 col-sm-4 col-6 mb30">
                  <span className="box-url">
                    <i className="fa fa-image"></i>
                    <h4>Memberships</h4>
                  </span>
                </div>

                <div className="col-lg-2 col-sm-4 col-6 mb30">
                  <span className="box-url">
                    <i className="fa fa-image"></i>
                    <h4>VIP PASS</h4>
                  </span>
                </div>

                <div className="col-lg-2 col-sm-4 col-6 mb30">
                  <span className="box-url">
                    <i className="fa fa-image"></i>
                    <h4>Community Tickets</h4>
                  </span>
                </div>
              </div>
            </div>
          </section>

          <section className='container no-top no-bottom'>
            <div className='row'>
              <div className="spacer-double"></div>
              <div className='col-lg-12 mb-2'>
                <h2>Items On Sale</h2>
              </div>
            </div>
            <div className='nft'>
        
            <div className="row" >
            {
              listed.map(d=>{
               return (walletAddress.toLowerCase() !== d.owner.toLowerCase() && d.isAvailable) && <CustomSlide  className='itm col-sm-12 col-md-6 col-xl-4 ' index={1}>
                <div className="d-item " >
                    <Link onClick={()=>{setSelectedNFT(d)}} to="/ButItem">
                  <div className="nft__item" >
                   
                        <span >
                            <h4>NAME : {d.jsonData.name}</h4>
                        </span>
                        <span >
                            <h4>TOKEN ID : {d.tokenID}</h4>
                        </span>
                        <span >
                            <h4>EDITION : {d.jsonData.edition}</h4>
                        </span>
                      <div className="nft__item_wrap">
                        <Outer>
                        
                          <span>
                              <img src={d.jsonData.image} className="lazy nft__item_preview" alt=""/>
                          </span>
                        
                        </Outer>
                      </div>
                      <div className="nft__item_info">
                          
                          <div className="nft__item_price">
                             PRICE : {d.price} BNB
                          </div>
                          <div className="nft__item_action">
                              <span >Buy Now</span>
                          </div>
                                               
                      </div> 
                  </div>
                  </Link>
                </div>
                </CustomSlide>
              })
            }



      
         </div>

      
        </div>

           
            
          </section>

        </Fragment>

      }
      <br />
      <Footer />

    </div>
  )

};
export default AllNFTs;