import React, { useContext, Component, Fragment, useState, useEffect } from 'react';
import SliderCarousel from '../components/SliderCarouselsingle';
import FeatureBox from '../components/FeatureBox';
import CarouselCollection from '../components/CarouselCollection';
import { createGlobalStyle } from 'styled-components';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import { useHookstate } from '@hookstate/core';
import { GlobalContext, globalState } from "components/app";
import SliderMainParticle from '../components/SliderMainParticle';
import { Link } from "@reach/router";
import Slider from "react-slick";
import styled from "styled-components";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { json } from 'react-router';
import { Redirect } from '@reach/router';

import { characterAddress,marketplaceAddress,network } from 'ContractABI/constants';
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const inline = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  .d-inline{
    display: inline-block;
   }
`;

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar .search #quick_search{
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-3{
    display: block !important;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-3{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
`;


const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 260px;
  overflow: hidden;
  border-radius: 8px;
`;



class CustomSlide extends Component {
    render() {
        const { index, ...props } = this.props;
        return (
            <div {...props}></div>
        );
    }
}

var settings = {
    infinite: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1900,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,

            }
        },
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,

            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,

            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true
            }
        }
    ]
};



const Profile = () => {
    // const appState = useHookstate();
    const { isLogin , walletAddress} = useContext(GlobalContext)
    var [JSONData, setJSONData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
   
    const loadData = async () => {
        let b = {
            "chain": "binance",
            "network": network,
            "account": walletAddress,
            "contract": characterAddress
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(b)
        };
        var response = await fetch('https://api.gaming.chainsafe.io/evm/all721', requestOptions);
        const data = await response.json();
        const DataJSON = JSON.parse(data.response);
   
       setJSONData(DataJSON);
       setIsLoading(false);
    }

    useEffect(() => {
        setIsLoading(true);
        loadData();

    }, [, walletAddress]);



    const sliders = () => {

        //console.log(JSONData);
        return JSONData.map((d, i) => {

            return (
                <NFTCard {...d} {...i} />
            )
        });

    }



    return (
        <>
            {
                isLogin ?
                    <Fragment>


                        <section id='profile_banner' className='jumbotron breadcumb no-bg' style={{ backgroundImage: `url(${'./img/background/9.jpg'})` }}>
                            <div className='mainbreadcumb'>
                            </div>
                        </section>



                        <section className='container d_coll no-top no-bottom'>
                            <div className='row'>
                                <div className="col-md-12">
                                    <div className="d_profile">
                                        <div className="profile_avatar">
                                            <div className="d_profile_img">
                                                <img src="./img/hamsterSwapLogo.png" alt="" />
                                                <i className="fa fa-check"></i>
                                            </div>

                                            <div className="profile_name">
                                                <h4>
                                                   
                                                    <div className="clearfix"></div>
                                                    <span id="wallet" className="profile_wallet">{walletAddress}</span>
                                                    <button id="btn_copy" title="Copy Text">Copy</button>
                                                </h4>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>

                      

                        <section className='container no-top no-bottom'>
                           


                            <div className='nft'>
                                { 
                                !isLoading &&
                                <Slider {...settings}>
                                    {

                                        sliders()
    

                                    }
                                </Slider>
                                }
                            </div>
                        </section>
                    </Fragment>
                    : <Redirect to="/" />
            }
        </>



    )
}

export default Profile;



export const NFTCard = (d, i) => {
    const { selectedNFT,setSelectedNFT } = useContext(GlobalContext)

    const [isLoading, setIsLoading] = useState(true);
    const [data, seturiData] = useState();

    const loadJson = async () => {
        const jsonData = await fetch(d.uri);
        const newJson = await jsonData.json();

        seturiData(newJson);
        setIsLoading(false);
    }

    useEffect(() => {
        loadJson();
    }, []);

    if (!isLoading) {
        
        return (
            <div index={i} key={i}>
                <CustomSlide className='itm' index={i}>
                    <div className="d-item">
                        <div className="nft__item">
                            <div className="nft__item_wrap">
                                <Outer>
                                    <Link to="/ItemDetail" onClick={()=> setSelectedNFT(d)}>
                                        <span>
                                            <img src={data.image} className=" nft__item_preview" alt="" />
                                        </span>
                                    </Link>
                                </Outer>
                            </div>
                            <div className="nft__item_info">
                                <span onClick={() => window.open("/#", "_self")}>

                                    <h4> Token ID = {d.tokenId}</h4>
                                   
                                </span>
                                <div className="nft__item_price">
                                    <span style={{color:"black",fontWeight:'bold'}}>Rarity</span> = {data.edition}
                                </div>
                               

                            </div>
                        </div>
                    </div>
                </CustomSlide>
            </div>
        )
    } else {
        return null;
    }
}